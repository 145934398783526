import {formatDate} from "@/filters";
import {getCurrentDate, getPrintDateRange, isExpanded} from "@/utils/reports";

export default {
    props: [
        'organization', 'print_data', 'global_settings', 'scale',
        'date_start', 'date_end', 'generate', 'open_all',
        'generate_xlsx', 'collapse_button', 'expand_button',
        'generate_email', 'email_address'
    ],
    computed: {
        navWidth() {
            if (this.$vuetify.breakpoint.xs) {
                return '90%'
            }

            if (this.$vuetify.breakpoint.sm) {
                return '70%'
            }

            return '40%'
        }
    },
    data() {
        return {
            showExpand: false,
            x: 0,
            y: 0,
            expand: null,
            zoom: 1,
            max_lvl: 0,
            items: [],
            setting_dialog: this.global_settings,
            current_date: this.getCurrentDate(),
            settings_watcher: null,
            generate_watcher: null,
            generate_xlsx_watcher: null,
            open_all_watcher: null,
            generate_email_watcher: null,
            local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
            by_grouping: false
        }
    },
    methods: {
        formatDate,
        getCurrentDate,
        getPrintDateRange,
        isExpanded,
        onNavInput(e) {
            if (!e) {
                this.$emit('closeSettings')
            }
        },
        toggleExpand(level) {
            let lvl = 0
            lvl = level === -1
                ? this.expand ? this.max_lvl : 0
                : level

            const arr = this.expand ? [...Array(lvl+1).keys()] : [...Array(this.max_lvl+1).keys()].filter(i => i >= lvl).reverse()
            arr.forEach(el => {
                this.toggleExpand_one(el)
            })
        },
        toggleExpand_one(level) {
            this.$nextTick(() => {
                const selector = `[data-level="level-${level}"]`
                document.querySelectorAll(selector).forEach(el => {
                    const el_class = el.className
                    const button_id = el.dataset.button
                    if (el_class.indexOf('details') === -1) {
                        const btn = document.getElementById(button_id)
                        if (!btn) return
                        const close = btn.classList.contains('mdi-plus')

                        if (this.expand) {
                            if (close) {
                                this.showRows(el_class, button_id)
                            }
                        } else {
                            if (!close) {
                                this.showRows(el_class, button_id)
                            }
                        }
                    }
                })
            })
        },
        pushUp(payload) {
            let current_index = this.grouping.indexOf(payload)
            // let arr_length = this.grouping.length - 1
            let new_arr = []

            if (current_index === 0) {
                new_arr = [...this.grouping.slice(1).concat([payload])]
            }
            //
            // if (current_index === 1) {
            //   new_arr = [...this.grouping.slice(1).concat(this.grouping.slice(0,1))]
            // }

            if (current_index >= 1) {
                new_arr = [
                    ...this.grouping.slice(0, current_index - 1).concat([payload])
                        .concat(this.grouping.slice(current_index-1, current_index))
                        .concat(this.grouping.slice(current_index+1))
                ]
            }
            this.grouping = [...new_arr]
        },
        pushDown(payload) {
            let current_index = this.grouping.indexOf(payload)
            let arr_length = this.grouping.length - 1
            let new_arr = []

            if (current_index === arr_length) {
                new_arr = [...[payload].concat(this.grouping.slice(0, current_index))]
            } else {
                new_arr = [
                    ...this.grouping.slice(0, current_index)
                        .concat(this.grouping.slice(current_index + 1, current_index + 2))
                        .concat(this.grouping.slice(current_index, current_index + 1))
                        .concat(this.grouping.slice(current_index + 2))
                ]
            }

            this.grouping = [...new_arr]
        },
        getCollapseIcon(level) {
            return level < this.max_lvl - 1 ? 'mdi-minus' : 'mdi-plus'
        },
        getCollapseIconNew(level) {
            return level < this.max_lvl ? 'mdi-minus' : 'mdi-plus'
        },
        showRows(group_class, button_id) {
            const button = document.getElementById(button_id)
            const close = button.classList.contains('mdi-plus')
            const groups = document.getElementsByClassName(group_class)
            const group_split = group_class.split(' ')
            let group_without = []

            Array.from(groups).forEach(el => {
                const cls_name = el.className.split(' ')
                if (close) {
                    if (cls_name.length === group_split.length + 1) {
                        if (!group_without.includes(el)) {
                            group_without.push(el)
                        }
                    }
                } else {
                    if (el.className.trim() !== group_class.trim()) {
                        if (!group_without.includes(el)) {
                            group_without.push(el)
                        }
                    }
                }
            })

            if (close) {
                button.classList.remove('mdi-plus')
                button.classList.add('mdi-minus')
                group_without.forEach(el => {
                    el.style.display = ''
                })
            } else {
                button.classList.remove('mdi-minus')
                button.classList.add('mdi-plus')
                group_without.forEach(el => {
                    el.style.display = 'none'
                    Array.from(el.getElementsByTagName('button')).forEach(btn => {
                        btn.classList.remove('mdi-minus')
                        btn.classList.add('mdi-plus')
                    })
                })
            }
        },
        closeSettings() {
            this.setting_dialog = false
            this.$emit('closeSettings')
        },
        watch_settings() {
            this.settings_watcher = this.$watch(
                'global_settings',
                {
                    handler(payload) {
                        this.setting_dialog = payload
                    }
                }
            )
        },
        watch_generate() {
            this.settings_watcher = this.$watch(
                'generate',
                {
                    handler(payload) {
                        if (payload) {
                            this.generate_report()
                        }
                    }
                }
            )
        },
        watch_generate_xlsx() {
            this.generate_xlsx_watcher = this.$watch(
                'generate_xlsx',
                {
                    handler(payload) {
                        if (payload) {
                            this.generate_xlsx_file()
                        }
                    }
                }
            )
        },
        watch_generate_email() {
            this.generate_email_watcher = this.$watch(
                'generate_email',
                {
                    handler(payload) {
                        if (payload) {
                            this.generate_email_send()
                        }
                    }
                }
            )
        },
        watch_open_all() {
            this.open_all_watcher = this.$watch(
                'open_all',
                {
                    handler(payload) {
                        if (payload !== undefined) {
                            this.showExpand = false
                            const el = payload ? this.expand_button.$el : this.collapse_button.$el
                            const cord = el.getBoundingClientRect()
                            this.expand = payload
                            this.x = cord.left
                            this.y = cord.bottom + 2
                            this.$nextTick(() => {
                                this.showExpand = true
                            })
                            this.$emit('clearOpenAll')
                        }
                    }
                }
            )
        },
    },
    created() {
        this.watch_settings()
        this.watch_generate()
        this.watch_generate_xlsx()
        this.watch_open_all()
        this.watch_generate_email()
    },
    beforeDestroy() {
        if (this.settings_watcher) {
            this.settings_watcher()
        }
        if (this.generate_watcher) {
            this.generate_watcher()
        }
        if (this.open_all_watcher) {
            this.open_all_watcher()
        }
        if (this.generate_xlsx_watcher) {
            this.generate_xlsx_watcher()
        }
        if (this.generate_email_watcher) {
            this.generate_email_watcher()
        }
    }
}